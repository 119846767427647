import React, { useState, useRef } from 'react';
import axios from 'axios';
import logo from "../logo.png"

const FileUpload = () => {
  const [file, setFile] = useState(null);
  const [link, setLink] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState('');
  const [isDragging, setIsDragging] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setError('');
    }
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile) {
      setFile(droppedFile);
      setError('');
    }
  };

  const handleUpload = async () => {
    if (!file || isUploading) {
      return;
    }

    setIsUploading(true);
    setError('');
    setUploadProgress(0);

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axios.post('https://apps.maida.co:5000/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentCompleted);
        },
      });

      const fileLink = `https://apps.maida.co:5000/file/${response.data.fileId}/${encodeURIComponent(response.data.originalName)}`;
      setLink(fileLink);
      setIsUploading(false);
      setFile(null);
    } catch (error) {
      setError('Upload failed. Please try again.');
      setIsUploading(false);
    }
  };

  return (
    <div className="relative flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className="absolute w-24 h-24 top-8 left-8">
        <img src={logo} alt="Logo" className="object-contain w-full h-full"/>
      </div>

      <div className="absolute bottom-4 right-4">
        <a 
          href="https://Maverickphp.com" 
          target="_blank" 
          rel="noopener noreferrer" 
          className="text-sm text-blue-600 hover:underline"
        >
          Developed by Maverickphp.com
        </a>
      </div>

      <div className="w-full max-w-md overflow-hidden bg-white rounded-lg shadow-md">
        <div className="p-6">
          <h1 className="mb-6 text-2xl font-bold text-center text-gray-800">File Upload</h1>
          
          <div className="space-y-4">
            <div 
              className={`flex flex-col items-center justify-center w-full h-48 border-2 border-dashed rounded-lg transition-colors ${
                isDragging ? 'border-blue-500 bg-blue-50' : 'border-gray-300 hover:border-gray-400'
              }`}
              onDragEnter={handleDragEnter}
              onDragLeave={handleDragLeave}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              <label htmlFor="dropzone-file" className="flex flex-col items-center justify-center w-full h-full cursor-pointer">
                <svg className="w-10 h-10 mb-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path>
                </svg>
                <p className="mb-2 text-sm text-gray-500">
                  <span className="font-semibold">Click to upload</span> or drag and drop
                </p>
                <p className="text-xs text-gray-500">
                  Upload one file at a time
                </p>
              </label>
              <input 
                id="dropzone-file" 
                type="file" 
                className="hidden" 
                onChange={handleFileChange}
                ref={fileInputRef}
              />
            </div>
            
            {file && (
              <p className="text-sm text-center text-gray-600">
                Selected file: {file.name}
              </p>
            )}
            
            <button
              onClick={handleUpload}
              disabled={!file || isUploading}
              className={`w-full p-3 text-sm font-semibold tracking-wide text-white uppercase transition-colors duration-200 transform rounded-lg ${
                !file || isUploading ? 'bg-gray-300 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-600'
              }`}
            >
              {isUploading ? 'Uploading...' : 'Upload File'}
            </button>
            
            {isUploading && (
              <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                <div 
                  className="bg-blue-600 h-2.5 rounded-full" 
                  style={{ width: `${uploadProgress}%` }}
                ></div>
              </div>
            )}
            
            {error && (
              <p className="text-sm text-center text-red-500">{error}</p>
            )}
            
            {link && (
              <div className="p-3 text-sm text-green-700 bg-green-100 border border-green-400 rounded-lg" role="alert">
                <p className="font-bold">File uploaded successfully!</p>
                <p>Your file link:</p>
                <a href={link} className="text-blue-500 break-all hover:underline" target="_blank" rel="noopener noreferrer">{link}</a>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FileUpload;