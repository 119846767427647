import React, { useState } from 'react';
import './App.css';
import FileUpload from './components/FileUpload';
import Login from './components/Login';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleLogin = () => {
    setIsAuthenticated(true);
  };

  return (
    <div className="App">
      {isAuthenticated ? (
        <FileUpload />
      ) : (
        <Login onLogin={handleLogin} />
      )}
    </div>
  );
}

export default App;